<template>
	<div class="">
		<h6>{{ program.name }}</h6>

		<div
			class=" "
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<h6 class="under-line-identify">신청 구분</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
							<tr>
								<th>영업단 구분 <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.type"
										class="pa-5-10 "
										@change="resetUpper"
									>
										<option value="">영업단 선택</option>
										<template
											v-for="(agency, index) in codes.agency_type"
										>
											<option
												v-if="index > user.roleCode && index <= codes.agency_type.length - 2"
												:key="'agency_' + index"
												:value="agency.code"
											>{{ agency.name }}</option>
										</template>
									</select>
								</td>
								<th>상위 영업단 <span class="color-red">*</span></th>
								<td>
									<div class="justify-space-between">
										<div class="flex-column justify-center">{{ item.upper_name ? item.upper_name : upper_name }}</div>
										<button
											v-if="isAgencySearch"
											class="bg-identify pa-5-10 vertical-middle"
											@click="getAgencyList"
										>영업단 조회</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>아이디 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.id"
										class="box pa-5-10 mr-10"
										placeholder="아이디를 입력하세요"
										maxlength="25"
										:rules="[rules.id(item, 'id', { min: 6, max: 20})]"
									/>

									<button
										class="pa-5-10 bg-gray vertical-middle"
										:class="{ 'bg-green': is_id_check}"
										@click="doIdCheck"
									>중복 체크</button>
								</td>
								<th>가입일 <span class="color-red">*</span></th>
								<td class="position-relative">
									<input v-model="item.date" placeholder="가입일" class="input-box" readonly @click="is_data_pick = !is_data_pick"/>
									<v-date-picker
										v-if="is_data_pick"
										v-model="item.date"
										no-title
										scrollable
										class="position-absolute"
										style="top: 45px; left: -5px"
										@change="is_data_pick = false"
									></v-date-picker>
								</td>
							</tr>
							<tr>
								<th>비밀번호 <span class="color-red">*</span></th>
								<td class="">
									<input
										v-model="item.password"
										type="password"
										class="input-box full-width"
										placeholder="비밀번호"
										max="50"
									/>
								</td>

								<th>비밀번호 확인 <span class="color-red">*</span></th>
								<td class="">
									<input
										v-model="item.passwordCk"
										type="password"
										class="input-box full-width"
										placeholder="비밀번호를 한번 더 입력하세요"
										max="50"
									/>
								</td>
							</tr>
							<tr
								v-if="item.type == 1"
							>
								<th>수기결제 MID</th>
								<td class="">
									<input
										v-model="item.manualPaymentId"
										class="input-box full-width"
										placeholder="수기결제 MID"
										max="50"
									/>
								</td>

								<th>비밀번호 입력</th>
								<td class="">
									<input
										v-model="item.manualPaymentPassword"
										type="password"
										class="input-box full-width"
										placeholder="수기결제 계정 비밀번호"
										max="50"
									/>
								</td>
							</tr>

							<tr
								v-if="item.type == 1"
							>
								<th>빌키결제 MID</th>
								<td class="">
									<input
										v-model="item.billKeyPaymentId"
										class="input-box full-width"
										placeholder="빌키결제 MID"
										max="50"
									/>
								</td>

								<th>비밀번호 입력</th>
								<td class="">
									<input
										v-model="item.billKeyPaymentPassword"
										type="password"
										class="input-box full-width"
										placeholder="빌키결제 계정 비밀번호"
										max="50"
									/>
								</td>
							</tr>
							<tr
								v-if="item.type == 1"
							>
								<th>IV</th>
								<td class="">
									<input
										v-model="item.iv"
										class="input-box full-width"
										placeholder="IV"
										max="50"
									/>
								</td>

								<th>KEY</th>
								<td class="">
									<input
										v-model="item.key"
										type="password"
										class="input-box full-width"
										placeholder="KEY"
										max="50"
									/>
								</td>
							</tr>

							<tr
								v-if="item.type == 1"
							>
								<th>SID</th>
								<td class="">
									<input
										v-model="item.sid"
										class="input-box full-width"
										placeholder="SID"
										max="50"
									/>
								</td>

								<th></th>
								<td class="">

								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="flex-1 bg-white pa-10">
					<h6 class="under-line-identify">사업자 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>사업자 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<label
									class="cont-radio"
									v-for="code in codes.P02"
									:key="code.TOTAL_CODE"
								>
									<input
										v-model="item.businessType"
										type="radio"
										:value="code.TOTAL_CODE"
										class="box"
									/>
									{{ code.code_name }}
								</label>
							</td>
						</tr>
						<tr
							v-if="item.businessType == '1'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.businessNumber"
									:ruels="[rules.max(item, 'businessNumber', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.businessType == '0'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.businessNumber"
									:ruels="[rules.max(item, 'businessNumber', 10)]"
									class="input-box"
									placeholder="사업자 등록 번호"
								/>
							</td>
							<th>법인등록번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.corporationNumber"
									:ruels="[rules.max(item, 'corporationNumber', 13)]"
									class="input-box"
									placeholder="법인 등록 번호"
								/>
							</td>
						</tr>
						<tr v-if="item.businessType == '2'">
							<th>주민등록 번호 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									v-model="item.frontSSN"
									type="number"
									:ruels="[rules.max(item, 'frontSSN', 6)]"
									class="input-box-inline mr-10"
									placeholder="주민등록 앞번호"
								/>
								<input
									v-model="item.backSSN"
									type="password"
									maxlength="7"
									class="input-box-inline"
									placeholder="주민등록 뒷번호"
								/>
							</td>
						</tr>
						<tr>
							<th>상호명 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="text"
									v-model="item.shopName"
									maxlength="50"
									class="input-box"
									placeholder="상호명을 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>대표자 성명 <span class="color-red">*</span></th>
							<td>
								<input
									type="text"
									v-model="item.name"
									maxlength="20"
									class="input-box"
									placeholder="대표자 성명을 입력하세요"
								/>
							</td>
							<th>휴대폰 번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.hp"
									:ruels="[rules.max(item, 'hp', 12)]"
									class="input-box"
									placeholder="대표자 휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>업태</th>
							<td class="si-desc"
								style="margin-right: 10px;">
								<input
									type="text"
									v-model="item.businessCondition"
									maxlength="20"
									class="input-box"
									placeholder="업태를 입력하세요"
								/>
							</td>
							<th>업종</th>
							<td>
								<input
									type="text"
									v-model="item.businessLine"
									maxlength="20"
									class="input-box"
									placeholder="업종을 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>사업장 주소 <span class="color-red">*</span></th>
							<td colspan="3">

								<input
									v-model="item.zipCode"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('company')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('company')"
								>주소 검색</button>

								<input
									v-model="item.address"

									class="input-box full-width mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('company')"
								>
								<input
									v-model="item.addressDetail"

									class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="mt-10 justify-space-between">
				<div class="flex-1 mr-10">
					<div class="pa-10 bg-white">
						<div
							class="under-line-identify justify-space-between"
						>
							<h6>서비스 이용 조건</h6>
							<span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
						</div>

						<table class="table th-left td-left">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>서비스 수수료 <span class="color-red">*</span></th>
								<td>
									<div class="justify-space-between">
										<div class="flex-1">
											상위 영업점 수익률
											<input v-model="item.upperFee" class="input-box-inline bg-identify text-right width-50px color-eee" readonly :rules="[rules.demical(item, 'upperFee', {min: 2, max: 2})]"/> %
										</div>
										<div class="flex-2">
											수익률
											<input
												v-model="item.fee" class="input-box-inline width-50px text-right" @change="checkFee()"
												:rules="[rules.demical(item, 'fee', { min: 2, max: 2})]"
											/> %
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>특별우대 이율</th>
								<td><input type="number" v-model="item.preferentialRate" class="input-box-inline text-center" :rules="[rules.demical(item, 'preferentialRate', { min: 2, max: 2})]"> % </td>
							</tr>
							<tr>
								<th>정산기준</th>
								<td class="text-left">위 에이전트는 PG사와의 계약관계에 근거하여 정산금을 결제대행 서비스를 통하여 지급받는 것에 동의합니다.</td>
							</tr>
							<tr>
								<th>정산주기</th>
								<td class="text-left">영업일 기준 / 월 정산</td>
							</tr>
                            <tr v-if="item.type == 1">
                                <th>배달비 지급 주기</th>
                                <td>
                                    <input type="radio" id="0" value="0" v-model="item.quickType"><label for="0" class="ml-5 mr-20">D+0</label>
                                    <input type="radio" id="1" value="1" v-model="item.quickType"><label for="1" class="ml-5">D+1</label>
                                </td>
                            </tr>
							<tr>
								<th>특약사항</th>
								<td>
									<ol class="text-left line-height-200">
										<li>1. 이행보험증 증권은 서비스 이용 신청일로부터 7일 이내에 제출한다.</li>
										<li>2. 정산 후 취소 시 발생하는 모든 제반 비용은 상기 에이전트가 책임을 진다.</li>
										<li>3. 단말기 분실 및 파손 시 통신비 1년 약정에 대한 비용은 상기 에이전트가 책임을 진다.</li>
										<li>4. 상기 에이전트는 하위 가맹점의 부실채권에 대해 공동의 책임을 진다.</li>
										<li>5. 상기 에이전트는 본사와의 계약기간동안 모집된 가맹점에 관하여 상호간의 협의없이 타결제 대항사와 에이전트 계약을 할 수 없다.</li>
									</ol>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="flex-1">
					<div class="bg-white pa-10">
						<h6>정산 계좌 정보</h6>
						<table class="table th-left td-left">
							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>은행 <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.bankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'account', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.accountHolder"
										class="input-box full-width"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-10 bg-white pa-10">
						<h6>부가정보</h6>
						<table class="table th-left td-left full-width">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>초기비용</th>
								<td>
									<div> 보증보험 증권
										<input
											v-model="item.performanceBondAmount"
											class="box width-10 pa-5-10 text-right"
											type="number"
											:rules="[rules.max(item, 'performanceBondAmount', 7)]"
										/> 만원
									</div>
									<p class="mt-10">기타 관리비: 단말기 및 통신비는 해당 PG사의 정책에 따라 별도 부가됨</p>
								</td>
							</tr>
							<tr>
								<th>계약서류 <span class="color-red">*</span></th>
								<td>
									<div>
										<label class="box pa-5-10 vertical-middle">
											<input
												v-show="false"
												type="file"
												multiple
												accept="image/*"
												@change="setFile"
											/>
											<v-icon
												small
											>mdi mdi-image-multiple</v-icon> 첨부파일
										</label>
									</div>

									<div class="mt-10">
										<button
											v-for="(file, index) in item.attachments"
											:key="'file_delete_' + index"
											@click="isDeleteFile(index)"
											class="btn-default pa-5-10 mr-10 mb-10"
										>{{ file.name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</div>
								</td>
							</tr>
              <tr v-if="item.type === '1'">
                <th>로고이미지</th>
                <td>
                  <div>
                    <label class="box pa-5-10 vertical-middle">
                      <input
                          v-show="false"
                          type="file"
                          accept="image/*"
                          @change="setLogoFile"
                      />
                      <v-icon
                          small
                      >mdi mdi-image-multiple</v-icon> 첨부파일
                    </label>
                  </div>

                  <div class="mt-10">
                    <button
                        v-if="item.logoImage"
                        @click="item.logoImage = ''"
                        class="btn-default pa-5-10 mr-10 mb-10"
                    >{{ item.logoImage[0].name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
                  </div>
                </td>
              </tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mtb-30 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="bg-ddd pa-10-20"
				@click="toList"
			>이전</button>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			:top="true"
			:bottom="false"

			title="상위 영업단 조회"
			height="550px"

			@close="clear"
		>
			<AgencySearchList
				slot="modal-content"

				:item_type="item.type"
				:user="user"

				@click="setUpper"
				@cancel="clear"
				@next="nextUpper"
			></AgencySearchList>
		</Modal>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";

export default {
	name: 'AgencyItem'
	,
	components: {AgencySearchList, DaumPost, Modal},
	props: ['Axios', 'user', 'codes', 'rules', 'date']
	,data: function(){
		return {
			program: {
				name: '하위 영업점 등록'
				, top: true
				, title: true
				, bottom: false
			}
			,fee_base: 4
			,is_data_pick: false
			,item: {
				type: ''
				,businessType: 0
				,date: this.date.getToday('-')
				,preferentialRate: 0
				,bankIdx: ''
				,performanceBondLimit: 71
				,attachments: []
                ,logoImage: ''
				,upperIdx: ''
				,upperFee: 0
				,fee: 0
				,frontSSN: ''
				,businessNumber: ''
				,corporationNumber: ''
				,performanceBondAmount: ''
        ,quickType: 0
			}
			,search: {
				page: 1
				,size: 10
			}
			,is_id_check: false
			,is_modal: false
			,modal_option: {

			}
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,agency_list: []
			,next_list: []
			,upperIdx: ''
			,upperFee: 0
		}
	}
	,computed: {
		isAgencySearch: function(){
			let is = false
			if(this.item.type > Number(this.user.roleCode) + 1){
				is = true
			}
			return is
		}
		,upper_name: function(){
			let upper_name = ''

			switch(this.user.roleCode){
				default: case '0':
					if(this.item.type == 1) {
						upper_name = '본사'
					}
					break;
				case '1':
					if(this.item.type == 2) {
						upper_name = this.user.branchName
					}
					break;
				case '2':
					if(this.item.type == 3) {
						upper_name = this.user.distributorName
					}
					break;
				case '3':
					if(this.item.type == 4) {
						upper_name = this.user.agencyName
					}
					break;
				case '4':
					break;
			}

			if(this.item.type == ''){
				upper_name = '영업단 구분을 선택하세요'
			}

			return upper_name
		}
	}
	, methods: {
		getAgencyList: async function(){
			this.is_modal = true
		}
		,getBankList: async function(){
			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'get'
					, url: 'common/bank'
				})
				if(result.success){
					this.$set(this.codes, 'P13', result.data)
					this.$emit('setNotify', { type: 'error', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,save: async function(){
            for (const [key, value] of Object.entries(this.item)) {
                if (value === 'null') {
                    this.item[key] = ''
                }
                if(typeof value === 'string') {
                    if(value.indexOf('undef') !== -1 ) {
                        this.item[key] = ''
                    }
                }
            }
            if (!this.item.logoImage) {
                delete this.item.logoImage
            }
			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'post'
					, url: 'branch/'
					, data: this.item
					, multipart: true
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.toList()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,clear: function(){
			this.is_modal = false
			this.upperIdx = ''
			this.agency_list = []
		}
		,toList: function(){
			this.$emit('push', {name: 'AgencyList'})
		}
		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'zipCode', call.zonecode)
			this.$set(this.item, 'address', call.address)

			this.is_post = false
		}
		,isDeleteFile: function(index){
			this.$delete(this.item.attachments, index)
		}
		,doIdCheck: async function(){
			try{
				this.is_id_check = false
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'branch/checkId'
					,data: {
						id: this.item.id
					}
				})
				if(result.success){
					if(result.data){
						this.$emit('setNotify', { type: 'success', message: '사용 가능한 아이디 입니다'})
						this.is_id_check = true
					}else{
						this.$emit('setNotify', { type: 'error', message: '사용중인 아이디입니다'})
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.clear()
				this.$emit('offLoading')
			}
		}
		,isFee: function(service){
			if(this.fee_base < this.item[service]){
				this.$emit('setNotify', { type: 'error', message: '본사수익률보다 높게 설정할 수 없습니다.'})
				this.item[service] = 0
			}
		}
		, setUpper: function(item){
			this.item.upperIdx = item.idx
			this.item.upper_name = item.name
			this.clear()
		}
		,getFee: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'branch/fee'
					,data: {
						upperAdminIdx: this.item.upperIdx
						,adminType: this.item.type
					}
				})
				if(result.success){
					this.upperFee = result.data.upperFee
					this.item.upperFee = (Number(this.upperFee) - Number(this.item.fee)).toFixed(2)
					this.item.availableFee = result.data.availableFee
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,setFile: function(e) {
      console.log(this.item.attachments)
			for (let i = 0; i < e.target.files.length; i++){

				this.item.attachments.push(e.target.files[i])
			}
      console.log(this.item.attachments)
		}
    ,setLogoFile: function(e) {
      if(e.target.files[0]){
        this.item.logoImage = []
        this.item.logoImage[0] = e.target.files[0]
      }
    }
		,resetUpper: function(){
			this.item.upperIdx = ''

			//if(this.user.roleCode > 0 && this.item.type == Number(this.user.roleCode) + 1){
			if(this.item.type == Number(this.user.roleCode) + 1){
				this.item.upperIdx = this.user.idx
			}
			this.item.upper_name = ''
		}
		,nextUpper: function(type, sub){
			this.upperIdx = sub.idx
			this.getAgencyList()
		}
		,checkFee: function(){
			if(this.upperFee < this.item.fee){
				this.$emit('setNotify', { type: 'error', message: '상위 영업단 수익보다 높을수 없습니다'})
				this.item.fee = 0
				this.item.upperFee = this.upperFee.toFixed(2)
			}else{
				this.item.upperFee = (Number(this.upperFee) - Number(this.item.fee)).toFixed(2)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)

		this.getBankList()
	}
	,watch: {
		'item.id': {
			handler: function(){
				this.is_id_check = false
			}
		}
		,'item.upperIdx': {
			immediate: true
			,handler: function(call){
				console.log(Number(this.item.type) + 1 + ' : ' + this.user.roleCode)
				if(call && Number(this.item.type) + 1 > this.user.roleCode) {
					this.getFee()
				}else{
					this.item.upperFee = 0
				}
			}
		}
		,'item.businessType': function(){
			this.item.businessNumber = ''
			this.item.corporationNumber = ''
			this.item.frontSSN = ''
			this.item.backSSN = ''
		}
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-picker__body { border: 1px solid #bbb}
</style>